<template>
    <b-row v-if="segment.name== 'Mentors & Mentees'">
        <b-col>
          <b-card tag="article">
            <b-col class="p-0">
              <b-card-title class="text-truncate">
                Mentors
              </b-card-title>
              <div class="d-flex mb-2 mt-2">
                <b-col>
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Matched
                      </h6>
                      <p class="font-small-3 m-0">
                        {{ matchedMentorsCount }}
                      </p>
                    </div>
                  </b-row>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Unmatched
                      </h6>
                      <p class="font-small-3 m-0">
                        {{ unmatchedMentorsCount }}
                      </p>
                    </div>
                  </b-row>
                </b-col>
              </div>

              <div class="mb-2">
                <div class="w-100 d-flex justify-content-between">
                  <p class="font-weight-bold m-0">
                    {{ matchedMentorsCount }} matched out of {{ mentorsCount }}
                  </p>
                  <p class="m-0">
                    {{ getPercentage(matchedMentorsCount, mentorsCount) }}%
                  </p>
                </div>
                <b-progress
                  :value="getPercentage(matchedMentorsCount, mentorsCount)"
                  class="w-100"
                  :class="
                    getPercentage(matchedMentorsCount, mentorsCount) > 50 ?
                      'default-green-chart-color' :
                      'default-chart-color'
                  "
                />
              </div>
            </b-col>
          </b-card>
        </b-col>
        <b-col>
          <b-card tag="article">
            <b-col class="p-0">
              <b-card-title class="text-truncate">
                Mentees
              </b-card-title>
              <div class="d-flex mb-2 mt-2">
                <b-col>
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Matched
                      </h6>
                      <p class="font-small-3 m-0">
                        {{ matchedMenteesCount }}
                      </p>
                    </div>
                  </b-row>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Unmatched
                      </h6>
                      <p class="font-small-3 m-0">
                        {{ unmatchedMenteesCount }}
                      </p>
                    </div>
                  </b-row>
                </b-col>
              </div>

              <div class="mb-2">
                <div class="w-100 d-flex justify-content-between">
                  <p class="font-weight-bold m-0">
                    {{ `${matchedMenteesCount} matched out of ${menteesCount}` }}
                  </p>
                  <p class="m-0">
                    {{ getPercentage(matchedMenteesCount, menteesCount) }}%
                  </p>
                </div>
                <b-progress
                  :value="getPercentage(matchedMenteesCount, menteesCount)"
                  class="w-100"
                  :class="
                    getPercentage(matchedMenteesCount, menteesCount) > 50 ?
                      'default-green-chart-color' :
                      'default-chart-color'
                  "
                />
              </div>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
</template>

<script>
import { BProgress, BCard, BRow, BCol, BCardTitle,
  BAvatar } from "bootstrap-vue";
export default {
  name: "MentorsAndMentees",
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  components: {
    BCardTitle,
    BAvatar,
    BCard,
    BProgress,
    BRow,
    BCol,
  },
  computed: {
    unmatchedMenteesCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.unmatched_mentees_count || 0;
    },

    matchedMenteesCount() {
      if (!this.program) {
        return 0;
      }
      return this.menteesCount - this.program.unmatched_mentees_count || 0;
    },
    unmatchedMentorsCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.unmatched_mentors_count || 0;
    },

    matchedMentorsCount() {
      if (!this.program) {
        return 0;
      }
      return this.mentorsCount - this.program.unmatched_mentors_count || 0;
    },
    menteesCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.mentees_count || 0;
    },
    mentorsCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.mentors_count || 0;
    },


  },
  methods: {
    getPercentage(achieved, total) {
      if (!achieved) {
        return 0;
      }
      return Math.round((achieved * 100) / total) || 0;
    },
  },
};
</script>
