<template>
    <div></div> 
   </template>
   
   <script>
   
   export default {
     name: "SurveySummary",
     props: {
       segment: {
         type: Object,
         default: null,
       },
     }
   };
   </script>
   