<template>
  <b-row>
    <b-col class="d-flex flex-column">
      <b-card class="flex">
        <h4>Participant overview</h4>
        <b-row class="mt-2 ">
          <b-col class="d-flex flex-column justify-content-between">
            <div class='d-flex flex-column justify-content-between'>
            <h1>{{ activeParticipantsCount }}</h1>
            <p>Active participants</p>
            </div>
           
          </div>
          </b-col>
          <b-col>
            <apexchart
              type="donut"
              width="380"
              :options="optionsParticipantOverview"
              :series="[activeParticipantsPercentage]"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="d-flex flex-column">
      <b-card style="flex:1">
        <h4>Training progress</h4>
        <b-row class="mt-2">
          <b-col class="d-flex flex-column justify-content-end">
            <div class="d-flex align-items-center mt-1" >

              <div class="d-flex flex-column">
                <h1 class="font-weight-bolder mb-0 active-programs">
                  {{ trainingNotStartedCount }}
                </h1>
                <p class="card-text font-small-3 mb-0">Training not started</p>
                
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
             
        
              <div class="d-flex flex-column">
                <h1 class="font-weight-bolder mb-0 active-programs">
                  {{ trainingStartedCount }}
                </h1>
                <p class="card-text font-small-3 mb-0">Training Started</p>
          
              </div>
            </div>
            <div class="d-flex align-items-center  mt-2">
              
              <div class="d-flex flex-column">
                <h1 class="font-weight-bolder mb-0 active-programs">
                  {{ trainingCompletedCount }}
                </h1>
                <p class="card-text font-small-3 mb-0">Training Completed</p>
                
              </div>
            </div>
          </b-col>
          <b-col>
            <apexchart
              type="donut"
              width="380"
              :options="optionsTrainingProgress"
              :series="trainingProgressPercent"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import { programTypes } from "@/models";


export default {
  name: "TrainingSessions",
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
      return {
        optionsTrainingProgress: {  
          colors: ["rgba(40, 199, 111, 1)", "rgba(40, 199, 111, 0.5), rgba(40, 199, 111, 0.16)"],
          labels: [
            "Training not started",
            "Training started",
            "Training completed",
          ],
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
        },
        optionsParticipantOverview: {     
          colors: ["rgba(40, 199, 111, 1)"],
          labels: [
            "Active",
          ],
          legend: {
            show: false,
          },
          dataLabels: {
              enabled: false,
              showOn: "always",
              name: {
                show: true,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function (val) {
                  return parseInt(val) + "%";
                },
              },
            },
     
        },
     
      }
  },

  computed: {
    activeParticipantsCount() {
      return this.program.active_participants_count;
    },
    activeParticipantsPercentage() {
      return (
        (this.activeParticipantsCount * 100) / this.program.participants_count
      );
    },
    trainingNotStartedCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.trainings_not_started_count || 0;
    },
    trainingStartedCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.trainings_started_count || 0;
    },
    trainingCompletedCount() {
      if (!this.program) {
        return 0;
      }
      return this.program.trainings_completed_count || 0;
    },
    isTrainingProgram() {
      return  this.program?.type_id === programTypes.TRAINING;
    },
    trainingProgressPercent() {
      let total =
        this.trainingNotStartedCount +
        this.trainingStartedCount +
        this.trainingCompletedCount;
      if (total === 0) {
        return [0];
      }
      return [
        (this.trainingNotStartedCount * 100) / total,
        (this.trainingStartedCount * 100) / total,
        (this.trainingCompletedCount * 100) / total,
      ];
    },
  },
  created () {
    programTypes
  }
};
</script>
