<template>
    <apexchart type="bar" :options="options" :series="options.series" height="240px" />
</template>

<script>


export default {
  name: "MatchProgress",
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        colors: ["#4c546d", "#9bcc65"],
        series: [],
        xaxis: {},
        yaxis: {
          title: {
            text: 'Downloads/Views'
          },
          labels: {
            formatter: function (val) {
              return Math.floor(val);
            }
          }
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
    };
  },
  created() {
    const {series, xaxis} = this.segment.data.dataset;
    this.options.series =  series; 
    this.options = {
      ...this.options,
      xaxis: {
        ...this.options.xaxis,
        max: xaxis.length,
        categories: xaxis
      }
    };
  },
};
</script>
