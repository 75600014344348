<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-end mb-4">
        <b-button
          :to="{name: 'champion-monthly-reports'}"
          variant="primary"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
          />
          Active Session Report
        </b-button>
    </div>
  <apexchart type="bar" :options="sessionsOptions" :series="sessionsOptions.series" height="240px" />
  </div>
</template>
<script>
import { reportService } from '@/services';
import { BButton } from 'bootstrap-vue';
export default {
  name: "MatchSummary",
  props: {
    program: {
      type: Object,
      default: () => {},
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  components: {
    BButton,
  },
  data() {
    return {
      sessionsOptions: {
        colors: ["#4c546d", "#9bcc65"],
        series: [],
        xaxis: {},
        yaxis: {
          title: {
            text: 'Sessions'
          }
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
    };
  },
  created() {
    const {series, xaxis} = this.segment.data.dataset;
    this.sessionsOptions.series =  series.map(s => {
      if(s.name === 'Unique Logins') {
        return {name: 'Unique Sessions', data: s.data};
      } else {
        return {name: 'Total Sessions', data: s.data};
      }
    });
    this.sessionsOptions = {
      ...this.sessionsOptions,
      xaxis: {
        ...this.sessionsOptions.xaxis,
        max: xaxis.length,
        categories: xaxis
      }
    };
  },
};
</script>
