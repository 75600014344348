<template>
  <b-button
    variant="outline-none"
    class="nav action-menu pr-0"
  >
    <b-nav-item-dropdown
      class="btn-icon"
      dropleft
    >
      <template 
        #button-content
      >
        <feather-icon
          icon="MoreVerticalIcon"
          size="20"
        />                
      </template>

      <action-dropdown-item
        v-if="segment.summary == null"
        @click="showSummaryTextarea(segment)"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Add text</span>
      </action-dropdown-item>
      <action-dropdown-item
        v-if="segment.summary != null"
        @click="showSummaryTextarea(segment)"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Edit text</span>
      </action-dropdown-item>
      <action-dropdown-item
        v-if="segment.summary != null"
        @click="deleteSegmentSummary(segment)"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Delete text</span>
      </action-dropdown-item>
    </b-nav-item-dropdown>
  </b-button>
</template>

<script>
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BButton, BNavItemDropdown,
} from 'bootstrap-vue';
import { reportService } from "@/services";
import { isDisplaySegment } from "@/@aom-core/utils/utils";
import { makeSuccessToast } from "@/libs/utils";

export default {
  components: {
    ActionDropdownItem,
    BButton, BNavItemDropdown,
  },
  props: {
    segment: {
      type: Object,
      required: true,
      default: () => {}
    },
    program: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  methods: {
    showSummaryTextarea(segment) {
      this.$emit('onShowSummaryTextarea', segment);
    },
    async deleteSegmentSummary(segment) {
      segment.summary = null;
      await reportService.updateProgramSegmentSummary(this.program.id, {
        segment_id: segment.id,
        summary: null
      });
      this.currentEditSummarySegmentId = null;
      this.$toast(makeSuccessToast('Summary deleted successfully.'));
    },
  },
  setup() {
    return {
      isDisplaySegment
    };
  }
};
</script>