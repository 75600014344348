<template>
  <b-tab
    title-item-class="flex-fill"
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Member engagement</span>
    </template>
    <div
      v-for="(segment, index) in engagementSegments" 
      :key="index"
    >
      <b-card class="mt-2">
        <b-row class="d-flex">
          <b-col>
            <h4>{{ segment.name }}</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <segment-summary-action
              v-if="segment.has_summary && !isShareReport"
              :segment="segment"
              :program="program"
              @onShowSummaryTextarea="showSummaryTextarea"
            />
          </b-col>
        </b-row>
        <component
          :is="getComponent(segment.name)"
          :segment="segment"
          :program="program"
          @update-segment-summary="updateSegmentSummary"
        />
        <div v-if="segment.has_summary">
          <div
            v-if="
              segment.summary != null && currentEditSummarySegmentId == null
            "
            v-html="segment.summary"
          />
          <div v-else-if="currentEditSummarySegmentId === segment.id">
            <aom-text-editor
              v-model="segment.summary"
              :min-height="300"
              :value="segment.summary"
            />
            <action-button
              variant="primary"
              class="mt-2"
              right
              @click="() => updateSegmentSummary(segment)"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span>Update Message</span>
            </action-button>
          </div>
        </div>
      </b-card>
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

import KeyResults from './segments/KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import SegmentSummaryAction from "./SegmentSummaryAction.vue";
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle,
  BButton, BNavItemDropdown
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import { makeSuccessToast } from "@/libs/utils";
import MatchProgress from "./segments/MatchProgress.vue";
import Sessions from "./segments/Sessions.vue";
import Resources from "./segments/Resources.vue";
import Communications from "./segments/Communications.vue";
import Training from "./segments/Training.vue";
import TrainingSessions from "./segments/TrainingSessions.vue";
import SurveyResponseRate from "./segments/SurveyResponseRate.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";

export default {
  components: {
    AomTextEditor,
    ActionButton,
    Training,
    SurveyResponseRate,
    Communications,
    Sessions,
    MatchProgress,
    Resources,
    SegmentSummaryAction,
    VueGoodTable,
    ActionDropdownItem,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation,
    BButton, BNavItemDropdown,
    TrainingSessions
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => []
    },
    program: {
      type: Object,
      required: true,
      default: () => {}
    },
    isShareReport: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    isProgramTraining () {
      return this.program?.type_id === programTypes.TRAINING;
    },
    engagementSegments() {
      return this.reportSegments.filter(segment => segment.section == 'member_engagement');
    }
  },
  data() {
    return {
      currentEditSummarySegmentId: null,
      isUpdating: false
    };
  },
  methods: {
    async updateSegmentSummary(segment) {
      this.isUpdating = true;
      this.currentEditSummarySegmentId = null;
      await reportService.updateProgramSegmentSummary(this.program.id, {
        segment_id: segment.id,
        summary: segment.summary,
      });
      this.$toast(makeSuccessToast("Summary updated successfully."));
      this.isUpdating = false;
    },
    showSummaryTextarea(segment) {
      this.currentEditSummarySegmentId = segment.id;
    },
    getComponent(name) {
      const componentsMap = {
        "Match progress": "MatchProgress",
        "Sessions": "Sessions",
        "Resources": "Resources",
        "Communications": "Communications",
        "Training": "Training",
        "Training sessions": "TrainingSessions",
        "Survey response rate": "SurveyResponseRate",
      };
      return componentsMap[name] || "div"; // Fallback to a <div> if no match
    },
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>