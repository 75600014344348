<template>
    <b-card>
        <b-row>
          <b-col class="text-center">
            <h1>{{ delivered }}</h1>
            <p>Delivered</p>
          </b-col>
          <b-col class="text-center">
            <h1>{{ submitted }}</h1>
            <p>Submitted</p>
          </b-col>
          <b-col class="text-center">
            <h1>{{ not_submitted }}</h1>
            <p>Not Submitted</p>
          </b-col>
        </b-row>
      </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
export default {
  name: "SurveyStatistics",
  props: {
    segment: {
      type: Object,
      default: null,
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCard,
  },
  computed: {
    delivered() {
      return this.segment.data[0].user_forms_count;
    },
    not_submitted() {
      return this.segment.data[0].user_forms_not_started;
    },
    submitted() {
      return this.segment.data[0].user_forms_completed;
    },
  },
};
</script>
