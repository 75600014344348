<template>
  <b-tab
    title-item-class="flex-fill"
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Survey Insights</span>
    </template>
    <b-tabs
      nav-class="d-flex justify-content-between"
      nav-item-class="flex-fill"
      content-class="tab-custom mt-2"
      nav-wrapper-class="mt-2 custom-wrapper"

    >
      <survey-initial
        v-if="surveyInitialSegments.length > 0"
        :segments="surveyInitialSegments"
        :program="program"
      />
      <survey-progress
        v-if="surveyProgressSegments.length > 0"
        :segments="surveyProgressSegments"
        :program="program"
      />
 
      <survey-close
        v-if="surveyCloseSegments.length > 0"
        :segments="surveyCloseSegments"
        :program="program"
      />
     
    </b-tabs>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

import {
  BTabs,
  BTab,
} from 'bootstrap-vue';
import SurveyInitial from './SurveyInitial.vue';
import SurveyProgress from './SurveyProgress.vue';
import SurveyClose from './SurveyClose.vue';

export default {
  components: {
    BTabs,
    BTab,
    SurveyInitial,
    SurveyProgress,
    SurveyClose
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => []
    },
    isPrinting: {
      type: Boolean,
      required: false,
      default: false
    },
    program: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    surveyInitialSegments() {
      return this.reportSegments.filter(segment => segment.section == 'initial_survey');
    },
    surveyProgressSegments() {
      return this.reportSegments.filter(segment => segment.section == 'progress_survey');
    },
    surveyCloseSegments() {
      console.log(this.reportSegments.filter(segment => segment.section == 'close_survey'))
      return this.reportSegments.filter(segment => segment.section == 'close_survey');
    }
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
.custom-wrapper {
  background-color: white!important;
}
</style>