<template>
 <div></div> 
</template>

<script>

export default {
  name: "ExecutiveSummarySegment",
  props: {
    segment: {
      type: Object,
      default: null,
    },
  }
};
</script>
