<template>
  <b-row class="mt-4">
    <b-col
      style="max-width: 25%"
      class="text-center flex-column"
      v-for="(graph, name) in this.segment.data"
      :key="name"
    >
      <p>
        <apexchart
          height="100"
          type="radialBar"
          :options="options"
          :series="[graph.data.percent]"
        />
      </p>
      <h1>{{graph.data.percent}}%</h1>
      <h4>{{ graph.label }}</h4>
      <p @dblclick="showProgramGraphSummary(name)">
        <b-form-textarea
          v-if="currentGraphEdit == name"
          v-model="graphSummary[name].summary"
          @blur="updateProgramGraphSummary(name)"
        />
        <span v-else>{{ getProgramGraphSummary(name) }}</span>
      </p>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BFormTextarea } from "bootstrap-vue";
//eslint-disable-next-line
import { reportService } from "@/services";
import { makeSuccessToast } from "@/libs/utils";

export default {
  name: "ProgramSuccessMetrics",
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentGraphEdit: null,
      graphSummary: this.segment.data,
      options: {
        chart: {
          height: 20,
          type: "radialBar",
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
              },
            },
          },
        },
      },
    };
  },
  methods: {
    async updateProgramGraphSummary(graph) {
      let summary = this.getProgramGraphSummary(graph);
      await reportService.updateProgramGraphSummary(this.program.id, {
        name: graph,
        summary: summary,
      });
      this.currentGraphEdit = null;
      this.$toast(makeSuccessToast("Summary updated successfully."));
    },
    showProgramGraphSummary(graph) {
      this.currentGraphEdit = graph;
    },
    getProgramGraphSummary(graph) {
      const programSuccess = this.segment;
      if (programSuccess) {
        return programSuccess.data[graph].summary;
      }
      return "";
    },
  },
};
</script>

