<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="shareReport"
      ref="shareReport"
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      title="Share report"
      centered
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-row>
        <b-col sm="12">
          <validation-provider
            v-slot="validationContext"
            ref="email"
            rules="required"
            name="Email address"
          > 
            <b-form-group
              label="Share via email address"
              label-for="email"
              name="email"
            >
              <b-form-input
                id="email"
                v-model="emailAddress"
                placeholder="Add emails separated by commas"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            vid="time"
            :rules="{ required: true }"
            name="Expiration date"
            class="mr-1"
          >
            <label for="datepicker-from">Set expiration date</label>
            <b-form-datepicker
              v-model="expirationDate"
              :locale="browserLocale"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok}">
        <b-button
          size="md"
          variant="outline-secondary"
          @click="copyLink"
        >
          Copy link
        </b-button>
        <b-button
          size="md"
          variant="primary"
          @click="ok"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          <span
            v-if="isSubmitting"
          > Sending...</span>
          <span v-else>Send report</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { matchStatus, userRoles } from "@/models";
  import AomDatePicker from "@aom-core/AomDatePicker";
  import { localDateAndTimeToUtc } from "@/libs/utils";
  import { matchesService, programsService } from "@services";
  import { makeErrorToast, makeSuccessToast, getValidationState } from "@/libs/utils";
  //eslint-disable-next-line
  import { required } from "@validations";
  import vSelect from "vue-select";
  import {
    BSpinner,
    BFormTextarea,
    BForm,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormTimepicker,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker
  } from "bootstrap-vue";
  import { mapGetters } from 'vuex';
  
  export default {
    components: {
      BFormDatepicker,
      BFormGroup,
      BFormInput,
      vSelect,
      BSpinner,
      BFormTextarea,
      BModal,
      BButton,
      BFormInvalidFeedback,
      BForm,
      BFormGroup,
      ValidationObserver,
      ValidationProvider,
      BFormTimepicker,
      AomDatePicker,
      BFormRadioGroup,
      BRow,
      BCol,
    },
    props: {
      isCreate: {
        type: Boolean,
        default: false,
      },
      matches: {
        type: Array,
        default: () => [],
      },
      programId: {
        type: [Number, String],
        default: 0,
      }
    },
    data() {
      return {
        isLoading: false,
        showModal: false,
        selectedMatchStatus: matchStatus.ACTIVE,
        matchStartDate: new Date(),
        matchStartTime: "09:00",
        minStartDate: new Date(),
        note: null,
        isSubmitting: false,
        otherReason: "",
        emailAddress: "",
        expirationDate: new Date(),
        browserLocale: navigator.language,
      };
    },
    computed: {
    },
    methods: {
      copyLink() {

      },
      open() {
        this.showModal = true;
      },
      onHidden() {
        this.clearForm();
      },
      async onSubmit() {
        try {
          this.isSubmitting = true;
          const programId = this.$route.params.id;
          const emails = this.emailAddress.split(';');
          let result = await programsService.shareReport(programId, {
              emails: emails,
              expiration_date: this.expirationDate
            });
          if (result) {
            this.$toast(makeSuccessToast('The report has been shared'));
          }
          this.showModal = false;
          this.isSubmitting = false;
        } catch(e) {
          console.log(e)
          this.$toast(makeErrorToast('Sorry the email could not be sent'));
        }
      },
      clearForm() {
        this.showModal = false;
        this.isSubmitting = false;
      },
    },
    setup() {
      return {
        getValidationState,
      };
    }
  };
  </script>
  
  <style>
  </style>