<template>
  <ul class="app-timeline">
    <li
      v-for="date in segment.data.dataset"
      :key="date.title"
      class="timeline-item"
      :class="[
        `timeline-variant-secondary`
      ]"
    >
      <div class="timeline-item-point" />

        <h3>
          {{ localeDateStringFromIsoDateTimeWithoutTimezone(date.date, false) }}
        </h3>

        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 class="d-flex align-items-center">
            {{ date.title }}

          </h6>
         
        </div>
  

    </li>
  </ul>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
//eslint-disable-next-line
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";

export default {
  name: "ProgramKeyDatesSegment",
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    segment: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      localeDateStringFromIsoDateTimeWithoutTimezone,
    };
  },
};
</script>


<style lang="scss" scoped>

.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
  display:flex;
  flex-wrap: wrap;
}

@import "~@/assets/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@/assets/scss/base/components/include"; // Components includes

// Color palettes
@import "~@/assets/scss/base/core/colors/palette-variables.scss";

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== "base" {
      .timeline-variant-#{$color_name}  {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  margin-top: 2rem;
  padding-top: 2rem;
  padding-left: 1.5rem;
  position: relative;

  flex-basis: 11%;

  border-top: 1px solid $timeline-border-color;
 
  // This gives shade to last timeline-item but isn't that revolutionary
 

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: -6px;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
