<template>
  <div>
  <b-card  v-for="mentee in mentees" :key="mentee.id">
     <div >
        <b-row>
          <b-col sm="12" class="d-flex justify-content-between align-items-center">
            <h4 class="text-left font-weight-bold m-0 mb-2">
              {{ mentee.translations[0].question_text }}
            </h4>
            <b-badge
              variant="light-success"
              class="font-weight-normal mb-1">Mentee Response</b-badge>
          </b-col>

          <b-col sm="12">
            <b-row class="d-flex align-items-center mb-3">
              <b-col sm="3" />
              <!-- Options -->
              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in mentee.choices"
                    :key="chIndex"
                    class="font-weight-bold text-center"
                  >
                    {{ choice.translations[0].choice_text }}
                  </b-col>
                </b-row>
              </b-col>
              <!-- End Options -->
            </b-row>

            <!-- Statements -->
            <b-row
              v-for="(statement, stIndex) in mentee.statements"
              :key="stIndex"
              class="d-flex align-items-center mb-3"
            >
              <b-col
                sm="3"
                class="text-left pb-1"
              >
                {{ statement.translations[0].question_text }}
              </b-col>

              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in mentee.choices"
                    :key="chIndex"
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <apexchart
                      type="radialBar"
                      :options="
                        getPercentage(statement.answers, choice) > 50
                          ? currentMatchPercentageGreenChartOptions
                          : currentMatchPercentageChartOptions
                      "
                      :series="[getPercentage(statement.answers, choice)]"
                      height="100"
                    />
                    <div class="survey-metric-rating-responses">
                      {{ getNumberResponses(statement.answers, choice) }}
                      /
                      {{ getTotalResponses(statement.answers) }}
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- End Statements -->
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card  v-for="mentor in mentors" :key="mentor.id">
     <div >
        <b-row>
          <b-col sm="12" class="d-flex justify-content-between align-items-center">
            <h4 class="text-left font-weight-bold m-0 mb-2">
              {{ mentor.translations[0].question_text }}
            </h4>
            <b-badge
              variant="light-warning"
              class="font-weight-normal mb-1">Mentors Response</b-badge>
          </b-col>

          <b-col sm="12">
            <b-row class="d-flex align-items-center mb-3">
              <b-col sm="3" />
              <!-- Options -->
              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in mentor.choices"
                    :key="chIndex"
                    class="font-weight-bold text-center"
                  >
                    {{ choice.translations[0].choice_text }}
                  </b-col>
                </b-row>
              </b-col>
              <!-- End Options -->
            </b-row>

            <!-- Statements -->
            <b-row
              v-for="(statement, stIndex) in mentor.statements"
              :key="stIndex"
              class="d-flex align-items-center mb-3"
            >
              <b-col
                sm="3"
                class="text-left pb-1"
              >
                {{ statement.translations[0].question_text }}
              </b-col>

              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in mentor.choices"
                    :key="chIndex"
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <apexchart
                      type="radialBar"
                      :options="
                        getPercentage(statement.answers, choice) > 50
                          ? currentMatchPercentageGreenChartOptions
                          : currentMatchPercentageChartOptions
                      "
                      :series="[getPercentage(statement.answers, choice)]"
                      height="100"
                    />
                    <div class="survey-metric-rating-responses">
                      {{ getNumberResponses(statement.answers, choice) }}
                      /
                      {{ getTotalResponses(statement.answers) }}
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- End Statements -->
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {  BCard, BRow, BCol,   BBadge,
    BProgress,} from "bootstrap-vue";

    import { STATUS_COLOR } from "@/libs/utils";
    import { AOM_GREEN_COLOR } from "@/libs/utils";
    import { userRoles, userRolesDisplay, questionTypes } from '@models';
export default {
  name: "QuestionScaleMetrics",
  components: {
    BCard,
    BRow,
    BCol,
    BCard,
    BBadge,
    BProgress,
  },
  props: {
    segment: {
      type: Object,
      default: null,
    },
  },
  data(){
    return {
        STATUS_COLOR,
        defaultChartColor: "#FFA500",
        defaultGreenChartColor: AOM_GREEN_COLOR,
        defaultChartOptions: {
            chart: {
            type: "radialBar",
            offsetY: -20,
            height: 180,
            sparkline: {
                enabled: true,
            },
            },
            plotOptions: {
            radialBar: {
                hollow: {
                margin: 0,
                size: "60%",
                },
                startAngle: -90,
                endAngle: 90,
                track: {
                background: "#e7e7e7",
                strokeWidth: "30%",
                margin: 5, // margin is in pixels
                },
                dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    offsetY: -2,
                    fontSize: "14px",
                },
                },
            },
            },
            grid: {
            padding: {
                top: -10,
            },
            },
            fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91],
            },
            },
            stroke: {
            lineCap: "round",
            },
        },
        questionTypes
    }
  },
  computed: {
    mentees() {
        return this.segment.data.mentees;
    },

    mentors() {
        return this.segment.data.mentors;
    },
    currentMatchPercentageGreenChartOptions() {
      return {
        ...this.defaultChartOptions,
        colors: [this.defaultGreenChartColor],
      };
    },

    currentMatchPercentageChartOptions() {
      return { ...this.defaultChartOptions, colors: [this.defaultChartColor] };
    },
  },
  methods: {
    getPercentage(answers, choice) {
      if (!answers) {
        return 0;
      }
      return (
        answers.length 
          ? parseFloat(
              ((answers.filter(a => a.choice_id === choice.id).length * 100) / answers.length).toFixed(2)
            )
          : 0
      );
    },
    getNumberResponses(answers, choice) {
      if (!answers) {
        return 0;
      }
      return answers.filter(a => a.choice_id === choice.id).length;
    },
    getTotalResponses(answers) {
      if (!answers) {
        return 0;
      }
      return answers.length;
    },
  },

  
};
</script>
