<template>
  <section v-if="!isLoading" :class="isShareReport? 'public-report': ''">
    <b-row>
      <b-col
 
        class="ml-auto text-right mb-2"
      >
        <b-badge
          v-if="lastCached"
          variant="light-success"
          class="font-weight-normal mr-2"
        >
        Last updated {{ timeAgo }}
        </b-badge>
        <b-avatar
          rounded
          variant="light-secondary">
          <feather-icon
            icon="Share2Icon"
            size="20"
       
            @click="openShareReportModal"
          />
        </b-avatar>
        <b-avatar 
          rounded
          variant="light-secondary"
          class="ml-2">
          <feather-icon
            
            icon="PrinterIcon"
            size="20"
            style="cursor: pointer;"
            @click="printWebpage"
          />
        </b-avatar>
      </b-col>
    </b-row>
    <b-card v-if="tokenExpired">
      <b-row>
        <b-col class="text-center">
          <h3>The report link has expired!</h3>
          <p>Please request the person who sent you this report to generate a new link</p>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      v-if="!tokenExpired"
      nav-class="d-flex justify-content-between"
      nav-item-class="flex-fill"
      :content-class="contentClass"
      pills
      card
    >
      <executive-summary 
        v-if="currentProgram && currentProgram.executive_summary &&  !isProgramTraining"
        :reportSegments="reportSegments"
        :program="defaultProgram"
        :isShareReport="isShareReport"
        :isPrinting="isPrinting"
      />
      <member-engagement 
        v-if="currentProgram && currentProgram.member_engagement"
        :reportSegments="reportSegments"
        :program="currentProgram"
        :isShareReport="isShareReport"
        :isPrinting="isPrinting"
      />
      <survey-insights
        v-if="currentProgram && (currentProgram.initial_survey || currentProgram.progress_survey || currentProgram.close_survey) &&  !isProgramTraining" 
        :reportSegments="reportSegments"
        :isShareReport="isShareReport"
        :isPrinting="isPrinting"
        :program="currentProgram"
      />
    </b-tabs>

    <share-modal 
      ref="shareReportModal"
    />
  </section>
  <div class="d-flex align-items-center justify-content-center" style="margin-top: 25%" v-else>
    <b-spinner
      style="width: 5rem; height: 5rem;"
      label="Loading..."
      variant="primary"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import KeyResults from './segments/KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import ExecutiveSummary from './ExecutiveSummary.vue';
import MemberEngagement from './MemberEngagement.vue';
import SurveyInsights from './SurveyInsights.vue';
import ShareModal from "./ShareModal.vue"; 
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle,
  BBadge,
  BSpinner
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import moment from "moment"; 

export default {
  name: 'ReportsOverview',
  components: {
    ShareModal,
    SurveyInsights,
    ExecutiveSummary,
    MemberEngagement,
    VueGoodTable,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation,
    BBadge,
    BSpinner
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.currentProgram?.type_id === programTypes.TRAINING;
    },
    currentProgram() {
      return this.program || this.defaultProgram;
    },
    isShareReport() {
      return this.$route.params.token !== undefined;
    },
    contentClass() {
      return 'tab-custom' + (this.isPrinting? ' printing': '');
    },
    timeAgo() {
      
      return moment(this.lastCached).fromNow(); 
    }
  },
  data() {
    return {
      isLoading: false,
      lastCached: null,
      isPrinting: false,
      token: null,
      tokenExpired: false,
      program: null,
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
      optionsParticipantOverview: {
        chart: {
          height: 400,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function(val) {
                  return parseInt(val) + "% active";
                },
              },
            }
          }
        },

      },
      reportSegments: []
    };
  }, 
  mounted() {
    window.onafterprint = this.handleAfterPrint;
  },
  methods: {
    handleAfterPrint() {
      this.isPrinting = false;
    },
    printWebpage() {
      this.isPrinting = true;
      this.$nextTick(() => {
        setTimeout(() => {
          window.print();
        }, 1000); // A slight delay ensures rendering
      });
    },
    openShareReportModal() {
      this.$refs.shareReportModal.open();
    },
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  },
  async created() {
    var response = null;
    try {
      const token = this.$route.params.token;
      this.isLoading = true;
      if (token) {
        this.token = token;
        response = await reportService.getPublicReportSegments(token);
        this.program = response.data.program;
      } else {
        response = await reportService.getReportSegments(this.$route.params.id);
      }

      this.reportSegments = response.data.items;

      this.lastCached = response.data.timestamp;
      this.isLoading = false
    } catch(e) {
      const { status, data } = e.response;
      if (status === 403) {
        this.tokenExpired = true;
      }
      this.isLoading = false
    }
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
.public-report {
  padding: 50px 50px;
}
@media print {
  body * {
    visibility: hidden;
  }

  .printing,
  .printing * {
    visibility: visible;
  }
}
.printing {
  position: absolute;
  left: 0;
  top: 0;
  .tab-pane {
    display: block!important;
  }
  .custom-wrapper {
    display: none!important;
  }
}
</style>