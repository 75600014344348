<template>
  <b-row class="mt-2">
    <b-col class="text-center" v-for="(survey, index) in surveys" :key="index">
      <h4>{{ survey.translations[0].title }}</h4>
      <div v-if="survey.user_forms_count > 0">
        <apexchart type="radialBar" :options="optionsParticipantOverview" :series="getSurveyResponsePercentage(survey)" />
        <p>{{ survey.user_forms_completed }} of {{ survey.user_forms_count }}</p>
      </div>
      <div v-else>
        <h4 class="mt-4">
          No data
        </h4>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, 
  BCol, 
} from 'bootstrap-vue';

export default {
  name: "SurveyResponseRate",
  components: {
    BCol,
    BRow
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionsParticipantOverview: {
        colors: ["rgb(155, 204, 101)"],
        chart: {
          height: 400,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function (val) {
                  return parseInt(val) + "%";
                },
              },
            }
          }
        },

      },
      surveys: []
    };
  },
  created() {
    this.surveys = this.segment.data.dataset.items;
  },
  methods: {
    getSurveyResponsePercentage(survey) {
      return [survey.user_forms_count? (survey.user_forms_completed * 100/survey.user_forms_count).toFixed(2): 0];
    },
  }
};
</script>