<template>
  <vue-good-table
    class="position-relative"
    mode="remote"
    :columns="columns"
    responsive
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: false,
    }"
    style-class="vgt-table "
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'label'">
        {{ props.formattedRow[props.column.field] }}
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}%</h1>

      </span> 
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

export default {
  name: "KeyResults",
  components: {
    VueGoodTable,
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rows: [],
      columns: [
        {
          label: "",
          field: "label",
          headerClass: "text-center",
          sortable: false,
          width: '70%',
          thClass: 'text-center' 
        },
        {
          label: "MENTORS",
          field: "mentor",
          headerClass: "text-center",
          tdClass: "text-center",
          sortable: false,
          thClass: 'text-center'
        },
        {
          label: "MENTEES",
          field: "mentee",
          headerClass: "text-center",
          tdClass: "text-center",
          sortable: false,
          thClass: 'text-center'
        },
      ],
    };
  },
  methods: {
    labelFormatter(id) {
      switch (id) {
        case "OverallSatisfaction":
          return "Highly satisfied or satisfied";
        case "DidYouAchieveYourGoals":
          return "Some, all goals, unintended outcomes";
        case "WouldYouRecommendTheProgram":
          return "Yes";
        case "RatingOfMentoringExperience":
          return "Excellent / Very Good / good";
        case "HowHasYourMentoringExperienceImpactedYourOpinionOfYourCompany":
          return "Highly or somewhat positive impact";
        default:
          return ''
      }
    },
  },
  mounted() {
    // Transform object into array
    this.rows = Object.entries(this.segment.data).map(([key, value]) => ({
      id: key,
      label: value.label,
      mentor: value.data.mentor.percent,
      mentee: value.data.mentee.percent,
      summary: value.summary
    }));
  }
};
</script>
<style lang="scss" scoped>
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
.vgt-table thead th {
  text-align: center !important;
}
</style>
