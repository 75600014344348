<template>
  <div>
  <!-- Multiple choice -->
   <b-card  v-for="mentee in mentees" :key="mentee.id">
  <div>
      <b-row>
        <b-col sm="12" class="d-flex justify-content-between align-items-center">
          <h4 class="text-left font-weight-bold m-0 mb-1">
            {{ mentee.translations[0].question_text }}
          </h4>
          <b-badge
            variant="light-success"
            class="font-weight-normal mb-1">Mentee Response</b-badge>
        </b-col>

        <b-col sm="12">
          <b-row class="d-flex align-items-center mb-1">
            <b-col
              sm="3"
              class="text-left text-truncate"
            />
            <b-col
              sm="7"
              class="text-center"
            />
            <b-col
              sm="2"
              class="text-center"
            >
              Responses
            </b-col>
          </b-row>
          <b-row
            v-for="(choice, chIndex) in mentee.choices"
            :key="chIndex"
            class="d-flex align-items-center mb-1"
          >
            <b-col
              sm="3"
              class="text-left text-truncate"
            >
              {{ choice.translations[0].choice_text }}
            </b-col>
            <b-col sm="6">
              <b-progress
                :value="getPercentage(mentee.answers, choice)"
                class="mr-1"
                :class="
                  getPercentage(mentee.answers, choice) > 50 ?
                    'default-green-chart-color' :
                    'default-chart-color'
                "
              />
            </b-col>
            <b-col
              sm="1"
              class="text-left"
            >
              {{ getPercentage(mentee.answers, choice) }}%
            </b-col>
            <b-col
              sm="2"
              class="text-center"
            >
              <b-badge
                variant="light-success"
                class="font-weight-normal"
              >
                {{ getNumberResponses(mentee.answers, choice) }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    </b-card>
    <b-card v-for="mentor in mentors" :key="mentor.id">
    <div>
      <b-row>
        <b-col sm="12" class="d-flex justify-content-between align-items-center">
          <h4 class="text-left font-weight-bold m-0 mb-1">
            {{ mentor.translations[0].question_text }}
          </h4>
          <b-badge
            variant="light-warning"
            class="font-weight-normal mb-1">Mentor Response</b-badge>
        </b-col>

        <b-col sm="12">
          <b-row class="d-flex align-items-center mb-1">
            <b-col
              sm="3"
              class="text-left text-truncate"
            />
            <b-col
              sm="7"
              class="text-center"
            />
            <b-col
              sm="2"
              class="text-center"
            >
              Responses
            </b-col>
          </b-row>
          <b-row
            v-for="(choice, chIndex) in mentor.choices"
            :key="chIndex"
            class="d-flex align-items-center mb-1"
          >
            <b-col
              sm="3"
              class="text-left text-truncate"
            >
              {{ choice.translations[0].choice_text }}
            </b-col>
            <b-col sm="6">
              <b-progress
                :value="getPercentage(mentor.answers, choice)"
                class="mr-1"
                :class="
                  getPercentage(mentor.answers, choice) > 50 ?
                    'default-green-chart-color' :
                    'default-chart-color'
                "
              />
            </b-col>
            <b-col
              sm="1"
              class="text-left"
            >
              {{ getPercentage(mentor.answers, choice) }}%
            </b-col>
            <b-col
              sm="2"
              class="text-center"
            >
              <b-badge
                variant="light-success"
                class="font-weight-normal"
              >
                {{ getNumberResponses(mentor.answers, choice) }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      
    </div>
  </b-card>
  </div>
</template>

<script>

import {  BCard, BRow, BCol,   BBadge,
 BProgress,} from "bootstrap-vue";

 import { STATUS_COLOR } from "@/libs/utils";
 import { AOM_GREEN_COLOR } from "@/libs/utils";
 import { userRoles, userRolesDisplay, questionTypes } from '@models';
export default {
name: "QuestionMetrics",
components: {
 BCard,
 BRow,
 BCol,
 BCard,
 BBadge,
 BProgress,
},
props: {
 segment: {
   type: Object,
   default: null,
 },
},
data(){
 return {
     STATUS_COLOR,
     defaultChartColor: "#FFA500",
     defaultGreenChartColor: AOM_GREEN_COLOR,
     defaultChartOptions: {
         chart: {
         type: "radialBar",
         offsetY: -20,
         height: 180,
         sparkline: {
             enabled: true,
         },
         },
         plotOptions: {
         radialBar: {
             hollow: {
             margin: 0,
             size: "60%",
             },
             startAngle: -90,
             endAngle: 90,
             track: {
             background: "#e7e7e7",
             strokeWidth: "30%",
             margin: 5, // margin is in pixels
             },
             dataLabels: {
             name: {
                 show: false,
             },
             value: {
                 offsetY: -2,
                 fontSize: "14px",
             },
             },
         },
         },
         grid: {
         padding: {
             top: -10,
         },
         },
         fill: {
         type: "gradient",
         gradient: {
             shade: "light",
             shadeIntensity: 0.4,
             inverseColors: false,
             opacityFrom: 1,
             opacityTo: 1,
             stops: [0, 50, 53, 91],
         },
         },
         stroke: {
         lineCap: "round",
         },
     },
     questionTypes
 }
},
computed: {
 mentors() {
    if(this.segment.data.mentors){
      return this.segment.data.mentors;
    }
 },
 mentees() {
  if(this.segment.data.mentees){
     return this.segment.data.mentees;
  }
 },
 currentMatchPercentageGreenChartOptions() {
   return {
     ...this.defaultChartOptions,
     colors: [this.defaultGreenChartColor],
   };
 },

 currentMatchPercentageChartOptions() {
   return { ...this.defaultChartOptions, colors: [this.defaultChartColor] };
 },
},
methods: {
 getPercentage(answers, choice) {
   if (!answers) {
     return 0;
   }
   return (
     answers.length 
       ? parseFloat(
           ((answers.filter(a => a.choice_id === choice.id).length * 100) / answers.length).toFixed(2)
         )
       : 0
   );
 },
 getNumberResponses(answers, choice) {
   if (!answers) {
     return 0;
   }
   return answers.filter(a => a.choice_id === choice.id).length;
 },
 getTotalResponses(answers) {
   if (!answers) {
     return 0;
   }
   return answers.length;
 },
},


};
</script>


<style lang="scss" scoped>
.survey-metric-rating-responses {
margin-top: -12px;
font-size: 12px;
}
::v-deep .default-chart-color {
.progress-bar {
  background-color: #FFA500 !important;
}
}
::v-deep .default-green-chart-color {
.progress-bar {
  background-color: #9bcc65 !important;
}
}
</style>

