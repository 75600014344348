<template>
    <b-row class="mt-2">
      <b-col class="text-center">
        <h1>{{ activeMatchesCount }}</h1>
        <p>Active</p>
      </b-col>
      <b-col class="text-center">
        <h1>{{ completedMatchesCount }}</h1>
        <p>Completed</p>
      </b-col>
      <b-col class="text-center">
        <h1>74</h1>
        <p>Rematched</p>
      </b-col>
      <b-col class="text-center">
        <h1>{{ cancelledMatchesCount }}</h1>
        <p>Cancelled</p>
      </b-col>
    </b-row>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
export default {
  name: "MatchSummary",
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    activeMatchesCount() {
      return this.program?.active_matches_count;
    },
    completedMatchesCount() {
      return this.program?.completed_matches_count;
    },
    cancelledMatchesCount() {
      return this.program?.cancelled_matches_count;
    }
  },
};
</script>
