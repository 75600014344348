<template>
  <b-tab  title-item-class="flex-fill" lazy>
    <template #title>
      <span class="font-weight-bold">Close</span>
    </template>
    <b-card>
      <h3>Close survey - For mentors and mentees</h3>
    </b-card>
    <div v-for="(segment, index) in segments" :key="index">
      <b-card>
        <b-alert
          variant="dark"
          class="p-2 d-flex align-items-center"
          show
        >
        <span class="icon">
        @
        </span>
           {{ segment.name }}
      
         </b-alert>
  
      </b-card>

        <component
          :is="getComponent(segment.name)"
          :segment="segment"
          :program="program"
          @update-segment-summary="updateSegmentSummary"
        />
 
      <b-card>
        <b-col class="d-flex justify-content-end">
            <segment-summary-action
              v-if="segment.has_summary && program && currentEditSummarySegmentId !== segment.id"
              :segment="segment"
              :program="program"
              @onShowSummaryTextarea="showSummaryTextarea"
            />
          </b-col>
        <div v-if="segment.has_summary">
          <div
            v-if="
              segment.summary != null && currentEditSummarySegmentId == null
            "
            v-html="segment.summary"
          ></div>
          <div v-else-if="currentEditSummarySegmentId === segment.id">
            <aom-text-editor
              :min-height="300"
              v-model="segment.summary"
              :value="segment.summary"
            />
            <action-button
              variant="primary"
              class="mt-2"
              right
              @click="() => updateSegmentSummary(segment)"
            >
              <span>Update Message</span>
            </action-button>
          </div>
        </div>
      </b-card>
    </div>
  </b-tab>
</template>

<script>
import { BAlert, BTab, BCard, BRow, BCol, BButton, BBadge, BProgress, BNavItemDropdown } from 'bootstrap-vue';
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import { isDisplaySegment } from "@/@aom-core/utils/utils";
import { reportService } from "@/services";
import { makeSuccessToast } from "@/libs/utils";
import SurveySummary from './surveys/SurveySummary.vue';
import QuestionScaleMetrics from './surveys/QuestionScaleMetrics.vue';
import SurveyStatistics from './surveys/SurveyStatistics.vue';
import SegmentSummaryAction from "./SegmentSummaryAction.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import QuestionMetrics from './surveys/QuestionMetrics.vue';
import AomTextEditor from "@aom-core/AoMTextEditor.vue";
export default {
  name: "SurveyClose",
  components: {
    BTab,
    BCard,
    BRow,
    BAlert,
    BCol,
    BButton,
    BBadge,
    BProgress,
    BNavItemDropdown,
    ActionDropdownItem,
    QuestionScaleMetrics,
    SurveyStatistics,
    QuestionMetrics,
    SegmentSummaryAction,
    ActionButton,
    AomTextEditor,
    SurveySummary
  },
  props: {
    segments: {
      type: Array,
      default: () => []
    },
    program: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      currentEditSummarySegmentId: null
    };
  },
  methods: {
    isDisplaySegment,
    showSummaryTextarea(segment) {
      this.currentEditSummarySegmentId = segment.id;
    },
    async updateSegmentSummary(segment) {

      this.currentEditSummarySegmentId = null;
      await reportService.updateProgramSegmentSummary(this.program.id, {
        segment_id: segment.id,
        summary: segment.summary,
      });
      this.$toast(makeSuccessToast("Summary updated successfully."));
    },
    getComponent(name) {
      console.log(name)
      const componentsMap = {
        "Statistics": "SurveyStatistics",
        "Mentoring sessions": "QuestionMetrics",
        "Achievement of mentee’s goals": "QuestionMetrics",
        "Partner suitability & satisfaction": "QuestionScaleMetrics",
        "Program structure, length & support": "QuestionMetrics",
        "Program elements": "QuestionScaleMetrics",
        "Overall experience": "QuestionMetrics",
        "Program Recommendations": "QuestionMetrics",
        "Benefits from the program": "QuestionScaleMetrics",
        "Outcomes of mentoring experience": "QuestionMetrics",// Still needs to be FIXED. 
        "Opinion of the organisation": "QuestionMetrics",
        "Mentor retention for future programs": "QuestionMetrics",
      };
      return componentsMap[name] || "div"; 
    }
}
};
</script>


<style lang="scss" scoped>
.icon {
  background-color:white;
  padding: 1px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-right: 10px;
  font-size: 20px;

}
</style>

