<template>
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-end mb-4">
        <b-button
        :to="{name: 'champion-monthly-comms'}"
        variant="primary"
      >
        <feather-icon
          icon="EyeIcon"
          size="16"
        />
        View Communications Report
      </b-button>
      </div>
    <apexchart type="bar" :options="commsOptions" :series="commsOptions.series" height="240px" />
      </div>
</template>
<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: "Communications",
  components: {
    BButton,
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      commsOptions: {
        colors: ["#9bcc65"],
        series: [],
        xaxis: {},
        yaxis: {
          title: {
            text: 'Communications'
          },
          labels: {
            formatter: function(val) {
              return Math.floor(val);
            }
          }
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      }
    };
  },
  created() {
    const {series, xaxis} = this.segment.data.dataset;
    this.commsOptions.series =  series; 
    this.commsOptions = {
      ...this.commsOptions,
      xaxis: {
        ...this.commsOptions.xaxis,
        max: xaxis.length,
        categories: xaxis
      }
    };
  },
};
</script>
