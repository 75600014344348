var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{ref:"shareReport",attrs:{"id":"shareReport","no-close-on-esc":"","no-close-on-backdrop":"","title":"Share report","centered":""},on:{"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('b-button',{attrs:{"size":"md","variant":"outline-secondary"},on:{"click":_vm.copyLink}},[_vm._v(" Copy link ")]),_c('b-button',{attrs:{"size":"md","variant":"primary"},on:{"click":ok}},[(_vm.isSubmitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isSubmitting)?_c('span',[_vm._v(" Sending...")]):_c('span',[_vm._v("Send report")])],1)]}}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{ref:"email",attrs:{"rules":"required","name":"Email address"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Share via email address","label-for":"email","name":"email"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"Add emails separated by commas","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"mr-1",attrs:{"vid":"time","rules":{ required: true },"name":"Expiration date"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"datepicker-from"}},[_vm._v("Set expiration date")]),_c('b-form-datepicker',{attrs:{"locale":_vm.browserLocale,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.expirationDate),callback:function ($$v) {_vm.expirationDate=$$v},expression:"expirationDate"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }