<template>
  <b-tab title-item-class="flex-fill">
    <!-- title -->
    <template #title>
      <feather-icon icon="UsersIcon" size="18" class="mr-50" />
      <span class="font-weight-bold">Executive Summary</span>
    </template>
    <div v-for="(segment, index) in executiveSegments" :key="index">
      <b-card :class="getAdditionalClasses(segment.name)" v-if="segment.name !== 'Mentors & Mentees' && segment.name !== 'Program Sessions'">
        <b-row class="d-flex header">
          <b-col>
            <h4 >{{ segment.name }}</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <segment-summary-action
              v-if="segment.has_summary && !isShareReport"
              :segment="segment"
              :program="defaultProgram"
              @onShowSummaryTextarea="showSummaryTextarea"
            />
          </b-col>
        </b-row>
        <component
          :is="getComponent(segment.name)"
          :segment="segment"
          :program="defaultProgram"
          @update-segment-summary="updateSegmentSummary"
        />
        <div v-if="segment.has_summary">
          <div
            v-if="
              segment.summary != null && currentEditSummarySegmentId == null
            "
            v-html="segment.summary"
          ></div>
          <div v-else-if="currentEditSummarySegmentId === segment.id">
            <aom-text-editor
              :min-height="300"
              v-model="segment.summary"
              :value="segment.summary"
            />
            <action-button
              variant="primary"
              class="mt-2"
              right
              @click="() => updateSegmentSummary(segment)"
            >
              <span>Update Message</span>
            </action-button>
          </div>
        </div>
      </b-card>
      <mentors-and-mentees
        v-else-if="segment.name == 'Mentors & Mentees'"
        :program="defaultProgram"
        :segment="segment"
      />
      <program-sessions
        v-else-if="segment.name == 'Program Sessions'"
        :program="defaultProgram"
        :segment="segment"
      />
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import { BRow, BCol, BTab, BCard } from "bootstrap-vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import { reportService } from "@/services";
import { isDisplaySegment } from "@/@aom-core/utils/utils";
import { makeSuccessToast } from "@/libs/utils";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";

import ProgramKeyDates from "./segments/ProgramKeyDates.vue";
import ExecutiveSummary from "./segments/ExecutiveSummary.vue";
import ProgramSuccessMetrics from "./segments/ProgramSuccessMetrics.vue";
import SegmentSummaryAction from "./SegmentSummaryAction.vue";
import ProgramSessions from "./segments/ProgramSessions.vue";
import MatchSummary from "./segments/MatchSummary.vue";
import KeyResults from "./segments/KeyResults.vue";
import MentorsAndMentees from "./segments/MentorsAndMentees.vue";
import ProgramEvaluation from "./segments/ProgramEvaluation.vue";

export default {
  components: {
    AomTextEditor,
    SegmentSummaryAction,
    ActionDropdownItem,
    BCard,
    BRow,
    BCol,
    BTab,
    ProgramKeyDates,
    ExecutiveSummary,
    ProgramSuccessMetrics,
    SegmentSummaryAction,
    ActionButton,
    ProgramSessions,
    MatchSummary,
    MentorsAndMentees,
    KeyResults,
    ProgramEvaluation
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    executiveSegments() {
      return this.reportSegments.filter(
        segment => segment.section == "executive_summary"
      );
    },
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => [],
    },
    program: {
      type: Object,
      required: true,
      default: () => {},
    },
    isShareReport: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      currentEditSummarySegmentId: null,
    };
  },
  methods: {
    getAdditionalClasses(name) {
      const classesMap = {
        "Program Key Dates": "mb-2",
        "Executive Summary": "mb-2",
        "Program Success Metrics": "mb-2",
        "Program Sessions": "mb-2",
        "Match summary": "mb-2",
        "Mentors & Mentees": "mb-2",
        "Key Results": "mb-2 px-0",
        "Program Evaluation": "mb-2  px-0",
      };
      return classesMap[name] || "";
    },
    getComponent(name) {
      const componentsMap = {
        "Program Key Dates": "ProgramKeyDates",
        "Executive Summary": "ExecutiveSummary",
        "Program Success Metrics": "ProgramSuccessMetrics",
        "Program Sessions": "ProgramSessions",
        "Match summary": "MatchSummary",
        "Mentors & Mentees": "MentorsMentees",
        "Key Results": "KeyResults",
        "Program Evaluation": "ProgramEvaluation"
      };
      return componentsMap[name] || "div"; // Fallback to a <div> if no match
    },

    showSummaryTextarea(segment) {
      this.currentEditSummarySegmentId = segment.id;
    },
    async updateSegmentSummary(segment) {
      this.currentEditSummarySegmentId = null;
      await reportService.updateProgramSegmentSummary(this.defaultProgram.id, {
        segment_id: segment.id,
        summary: segment.summary,
      });
      this.$toast(makeSuccessToast("Summary updated successfully."));
    },
  },
  setup() {
    return {
      isDisplaySegment,
    };
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}

.card.px-0 > .card-body{
  padding-left: 0;
  padding-right: 0;

  .header{
    padding-left: 21px;
    padding-right: 21px;
  }
}
</style>
