<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :group-options="{
        enabled: true,
        headerPosition: 'top',
      }"
      :line-numbers="false"
      theme="no-theme"
      styleClass="vgt-table custom-table"
    >
      <template slot="table-header-row" slot-scope="props">
        <span class="bold">
          <b> {{ props.row.label }}</b>
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'mentors'"
          :class="getColorClass(props.row[props.column.field], props.row.metric)"
        >
          {{ props.row[props.column.field] }}%
        </span>

        <span
          v-else-if="props.column.field === 'mentees'"
          :class="getColorClass(props.row[props.column.field], props.row.metric)"
        >
          {{ props.row[props.column.field] }} {{ props.row[props.column.field] && '%' }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

export default {
  name: "ProgramEvaluation",
  components: {
    VueGoodTable,
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  props: {
    program: {
      type: Object,
      default: null,
    },
    segment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        { label: " ", field: "metric" ,  width: '70%',   sortable: false,    thClass: 'text-center'}, // Empty label for proper alignment
        { label: "MENTORS", field: "mentors",      sortable: false,  tdClass: "text-center",  thClass: 'text-center'},
        { label: "MENTEES", field: "mentees",       sortable: false ,  tdClass: "text-center",   thClass: 'text-center'},
      ],
      rows: [
      ],
    };
  },
  created() {
      console.log();
      const metrics = this.segment.data
      this.rows = [{
          mode: "span",
          label: "Program outcomes", // this is the label that'll be used for the header
          children: [
            {
              metric: "85% satisfaction with resources and support",
              mentors: metrics.satisfactionWithResourcesAndSupport.data.mentor.percent,
              mentees: metrics.satisfactionWithResourcesAndSupport.data.mentee.percent,
            },
          ],
        },
        {
          mode: "span",
          label: "Mentoring relationship outcomes", // this is the label that'll be used for the header
          children: [
            {
              metric: "85% satisfaction with match",
              mentors: metrics.satisfactionWithMatch.data.mentor.percent,
              mentees: metrics.satisfactionWithMatch.data.mentee.percent,
            },
            { metric: "80% agreed goals achieved", 
              mentees: metrics.agreedGoalsAchieved.data.mentee.percent, 
              mentors: metrics.agreedGoalsAchieved.data.mentor.percent 
            },
          ],
        },
        {
          mode: "span",
          label: "Mentoring relationship outcomes", // this is the label that'll be used for the header
          children: [
            {
              metric: "80% mentors willing to mentor again",
              mentors: metrics.mentorsWillingToMentorAgain.data.mentor.percent,
              mentees: metrics.mentorsWillingToMentorAgain.data.mentee.percent,
            },
          ],
        }];

  },
  methods: {
    getColorClass(value, metric) {
      switch(metric) {
        case "85% satisfaction with resources and support":
          return value >= 85 ? "positive" : "negative";
        case "85% satisfaction with match":
          return value >= 85 ? "positive" : "negative";
        case "80% agreed goals achieved":
          return value >= 80 ? "positive" : "negative";
        case "80% mentors willing to mentor again":
          return value >= 80 ? "positive" : "negative";
      }
    },
  },
};
</script>
<style scoped>
/* Table styles */
.custom-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Section headers */
.section-header {
  font-weight: bold;
  background-color: #f8f9fa;
  padding: 10px;
}

/* Color styling for percentage values */
.positive {
  color: green;
  font-weight: bold;
}
.negative {
  color: red;
  font-weight: bold;
}

/* Remove default styling for empty headers */
.vgt-table thead th {
  background: none;
  border: none;
}

.vgt-left-align{
  text-align: center !important;
}
</style>
